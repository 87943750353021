<script setup lang="ts">
import type { Schemas } from "#shopware";
import type { NavEntry } from "~~/server/api/internal/navigation";

const props = defineProps<{
  controller: ReturnType<typeof useModal>;
}>();
const { controller } = toRefs(props);
const { isOpen, open, close } = controller.value;

const modalBackground = useModalBackground();
watch(isOpen, modalBackground.toggle);

const { locale } = useI18n();
const { languageIdChain } = useSessionContext();
const { data: navigationElements } = await useFetch(
  "/api/internal/navigation",
  {
    headers: {
      "accept-language": locale.value,
      "sw-language-id": languageIdChain.value,
    },
  },
);

const { breadcrumbs } = useBreadcrumbs();

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const { languages, switchLanguage } = useLanguageSwitcher();
const { t } = useI18n();

const { escape } = useMagicKeys();
if (escape) watch(escape, close);

interface MenuPage {
  id?: string;
  items: NavEntry[];
  header?: {
    name: string;
    path?: NavEntry["path"];
  };
  banner?: {
    media?: Schemas["Media"];
    link?: string;
  };
  products?: Schemas["Product"][];
}

const subMenuPoints = [
  { name: t("nav.company"), url: "https://www.scheppach.com" },
  { name: t("footer.factory-sales"), url: t("urls.factory-outlet") },
  { name: t("account.menu.career"), url: t("urls.career") },
  { name: t("spareParts.customerService"), url: "/contact" },
  { name: t("nav.news"), url: "https://www.scheppach.com" },
];

const level = ref(0);
const pages = ref<[MenuPage, MenuPage, MenuPage, MenuPage]>([
  { items: navigationElements as MenuPage["items"] },
  { items: [] },
  { items: [] },
  { items: [] },
]);

const banner = computed<NonNullable<MenuPage["banner"]> | false>(() => {
  for (let i = level.value; i >= 0; i--) {
    if (pages.value[i]?.banner)
      // what is going on here? why is this not type narrowing?
      return pages.value[i]?.banner || false;
  }

  return false;
});

const products = computed<NonNullable<MenuPage["products"]>>(() => {
  for (let i = level.value; i >= 0; i--) {
    if (pages.value[i]?.products)
      // what is going on here? why is this not type narrowing?
      return pages.value[i]?.products || [];
  }

  return [];
});

async function itemClick(
  item: NavEntry,
  clickLevel: number,
  menuBarClick = false,
) {
  if (item.type == "leaf") return navigate(item.path);

  assert(item.type === "node", "Ensure menu tree was resolved");

  if (menuBarClick && isOpen && pages.value[clickLevel + 1]?.id == item.id) {
    // if (item.path) return navigate(item.path);

    return close();
  }

  const nextMenuPage = {
    id: item.id,
    items: item.children,
    header: {
      name: item.name,
      path: item.path,
    },
  } as MenuPage;

  if (item.banner) {
    nextMenuPage.banner = item.banner;
  }

  if (item.products) {
    nextMenuPage.products = item.products;
  }

  pages.value[clickLevel + 1] = nextMenuPage;
  level.value = clickLevel + 1;

  if (menuBarClick) {
    const activeItem =
      nextMenuPage.items.find((x) => x.active) || nextMenuPage.items[0];

    if (activeItem?.type == "node") {
      pages.value[clickLevel + 2] = {
        id: activeItem.id,
        items: activeItem.children,
        header: {
          name: activeItem.name,
        },
      };
      level.value = clickLevel + 2;
    }

    open();
  }
}

function navigate(path: NavEntry["path"]) {
  if (typeof path == "string" && /^https?:\/\//.test(path)) {
    window.location.href = path;
  } else if (path) {
    navigateTo(formatLink(path));
  }
}

function back() {
  level.value--;
}

watch([breadcrumbs], () => setActiveState(pages.value));

function setActiveState(menuPages: MenuPage[]) {
  if (!pages) return;
  const breadcrumbNames =
    breadcrumbs?.value?.map((crumb) => crumb?.name || "") || [];
  if (breadcrumbNames.length <= 0) return;

  function traverseItems(items: NavEntry[]) {
    for (const item of items) {
      item.active = breadcrumbNames.includes(item.name);

      if (item.type === "node" && item.children && item.children.length > 0) {
        traverseItems(item.children);
      }
    }
  }

  for (const page of menuPages) {
    traverseItems(page.items);
  }
}

const headerOffset = inject<number>("headerOffset", 0);

function active(item: NavEntry, lvl: number) {
  return (
    (item.active && !pages.value[lvl]?.id) || item.id == pages.value[lvl]?.id
  );
}

watch(isOpen, (open) => {
  if (open) return;

  level.value = 0;
  pages.value = [
    { items: navigationElements as MenuPage["items"] },
    { items: [] },
    { items: [] },
    { items: [] },
  ];

  setActiveState(pages.value);
});

const { height } = useWindowSize();
</script>

<template>
  <!-- level 0 desktop -->
  <nav
    class="max-xl:hidden flex items-center font-700 h-full c-scheppach-primary-700"
  >
    <ul class="m-0 p-0 flex gap-4.5">
      <li
        v-for="item in pages[0].items"
        :key="item.id"
        class="list-none inline-block cursor-pointer whitespace-nowrap"
        :class="{ 'custom-underline': active(item, 1) }"
      >
        <NuxtLink
          v-if="item.type == 'node'"
          :to="item.path ? formatLink(item.path) : '#'"
          class="font-700"
          @click.stop.prevent.capture="itemClick(item, 0, true)"
        >
          {{ item.name }}
          <span
            class="ml-0.5 i-sl-arrow-left-1 size-3 inline-block align-middle transition-transform"
            :class="{ 'rotate--90': active(item, 1) }"
          ></span>
        </NuxtLink>

        <NuxtLink v-else :to="'/spare-parts'" class="font-700">
          {{ item.name }}
        </NuxtLink>
      </li>
    </ul>
  </nav>

  <div
    tabindex="0"
    class="xl:hidden size-10 cursor-pointer"
    :class="{ 'custom-underline': isOpen }"
    @click.prevent.stop="open"
  >
    <div class="h-full mx-auto w-5 i-sl-navigation-menu"></div>
  </div>

  <ClientOnly>
    <Teleport to="body">
      <Transition
        enter-active-class="transition ease-out duration-500 transform"
        enter-from-class="max-xl:-translate-x-full xl:-translate-y-full"
        enter-to-class="max-xl:translate-x-0 xl:translate-y-0"
        leave-active-class="transition ease-in duration-300 transform"
        leave-from-class="max-xl:translate-x-0 xl:translate-y-0"
        leave-to-class="max-xl:-translate-x-full xl:-translate-y-full"
      >
        <div
          v-if="isOpen"
          class="fixed top-0 bottom-0 left-0 right-0 max-xl:z-2000"
          :class="[level < 1 ? 'xl:hidden' : 'xl:block']"
          :style="{ marginTop: `${headerOffset}px` }"
          @click.self="close"
        >
          <div
            class="xl:bg-gradient-to-r from-[#336d9d] from-50% to-scheppach-primary-50 to-50% max-xl:h-full xl:h-192 overflow-x-hidden xl:mt-22.5 relative"
          >
            <div class="absolute z-1 h-18 py-4 px-5 xl:hidden w-full">
              <img
                class="h-12 mx-auto bg-white"
                src="/logo-scheppach.svg"
                alt="scheppach logo"
              />
              <button
                type="button"
                class="i-sl-close size-5 absolute right-5 top-7.5"
                :class="[
                  level < 2
                    ? 'c-scheppach-primary-50'
                    : 'c-scheppach-primary-700',
                ]"
                @click="close"
              ></button>
            </div>
            <div
              class="max-xl:relative max-xl:w-400% xl:max-w-[1480px] xl:mx-auto h-full flex items-stretch transition-left-250"
              :class="
                {
                  0: 'max-xl:left-0',
                  1: 'max-xl:left--100%',
                  2: 'max-xl:left--200%',
                  3: 'max-xl:left--300%',
                }[level]
              "
            >
              <div
                class="bg-[#336d9d] w-auto xl:w-85 flex text-scheppach-shades-0 max-xl:flex-grow max-xl:flex-basis-none max-xl:h-full"
              >
                <!-- level 0 mobile -->
                <div
                  class="p-5 pt-22 max-xl:flex-grow max-xl:flex-basis-none xl:hidden flex flex-col gap-6 relative bg-[#336d9d]"
                >
                  <NuxtLink
                    v-for="item of pages[0].items"
                    :key="item.id"
                    class="flex items-center cursor-pointer gap-3"
                    :to="item.path ? formatLink(item.path) : '#'"
                    @click.capture.prevent.stop="itemClick(item, 0)"
                  >
                    <div
                      class="flex-grow text-5 font-700 text-left truncate select-none"
                    >
                      {{ item.name }}
                    </div>
                    <div
                      v-if="item.type == 'node'"
                      type="button"
                      class="i-sl-arrow-right-1 size-4"
                    ></div>
                  </NuxtLink>
                  <hr />

                  <NuxtLink
                    v-for="(item, index) of subMenuPoints"
                    :key="index"
                    class="flex items-center cursor-pointer gap-3"
                    :to="item.url"
                  >
                    <div
                      class="flex-grow text-5 font-700 text-left truncate select-none"
                    >
                      {{ item.name }}
                    </div>
                  </NuxtLink>

                  <!-- deactivate language switching for now -->
                  <div
                    v-if="false"
                    class="flex gap-2 bg-scheppach-primary-500 c-scheppach-primary-100 p-5 pt-4 text-lg absolute bottom-0 left-0 right-0 xl:hidden block rd-tr-2.5"
                  >
                    <template
                      v-for="(language, index) in languages"
                      :key="language.locale"
                    >
                      <span v-if="index > 0" class="c-scheppach-primary-300">
                        |
                      </span>
                      <span
                        tabindex="0"
                        role="button"
                        class="hover:c-white hover:font-700 transition duration-150"
                        :class="{ 'font-700': language.active }"
                        @click="switchLanguage(language.locale)"
                      >
                        {{ language.name }}
                      </span>
                    </template>
                  </div>
                </div>
                <!-- level 1 -->
                <div
                  id="nav-level-1"
                  class="p-5 pt-22 max-xl:flex-grow max-xl:flex-basis-none xl:flex-grow xl:pt-12 xl:pr-6.5 xl:pb-15 xl:pl-5 flex flex-col gap-6 bg-[#336d9d]"
                >
                  <div
                    class="flex items-center cursor-pointer gap-3 xl:hidden"
                    @click="back"
                  >
                    <div class="size-4 i-sl-arrow-left-1"></div>
                    <span
                      class="flex-grow text-left font-800 truncate text-5 select-none"
                    >
                      {{ pages[1].header?.name ?? "" }}
                    </span>
                  </div>

                  <NuxtLink
                    v-for="item of pages[1].items"
                    :key="item.id"
                    class="flex items-center cursor-pointer gap-3"
                    :to="item.path ? formatLink(item.path) : '#'"
                    @click.capture.prevent.stop="itemClick(item, 1)"
                  >
                    <div
                      v-if="item.icon"
                      class="size-6"
                      :class="[item.icon]"
                    ></div>

                    <div
                      class="flex-grow text-5 font-700 text-left truncate select-none"
                    >
                      <span :class="{ 'custom-underline': active(item, 2) }">
                        {{ item.name }}
                      </span>
                    </div>

                    <div
                      v-if="item.type == 'node'"
                      class="i-sl-arrow-right-1 size-4"
                    ></div>
                  </NuxtLink>
                </div>
              </div>
              <div
                class="bg-scheppach-primary-50 xl:flex-grow flex flex-col xl:pt-12 xl:pb-15 xl:pl-6.5 xl:pr-5 xl:gap-12 xl:justify-between flex-grow flex-basis-none text-scheppach-primary-700 max-xl:h-full"
              >
                <div class="flex xl:grid xl:grid-cols-3 xl:gap-14 xl:relative">
                  <div
                    v-if="level < 2 && products.length > 0"
                    class="hidden xl:grid xl:grid-cols-3 xl:grid-rows-1 xl:col-span-3 gap-5 mr-10"
                  >
                    <ProductCard
                      v-for="product in products"
                      :key="product.id"
                      :product="product"
                      class="max-w-full min-w-0"
                    />
                  </div>
                  <!-- level 2 -->
                  <div
                    v-if="level >= 2"
                    id="nav-level-1"
                    class="p-5 pt-22 max-xl:flex-grow max-xl:flex-basis-none xl:p-0 flex flex-col max-xl:gap-6 xl:grid xl:grid-cols-subgrid xl:gap-y-3.5 xl:grid-auto-flow-col xl:grid-rows-10 max-xl:max-w-50% bg-scheppach-primary-50"
                    :class="{ 'xl:col-span-2': pages[2].items.length > 10 }"
                  >
                    <div
                      class="flex items-center cursor-pointer gap-3 xl:hidden"
                      @click="back"
                    >
                      <div class="size-4 i-sl-arrow-left-1"></div>
                      <span
                        class="flex-grow text-left font-800 truncate text-5 select-none"
                      >
                        {{ pages[2].header?.name ?? "" }}
                      </span>
                    </div>

                    <NuxtLink
                      v-for="item of pages[2].items"
                      :key="item.id"
                      :to="item.path ? formatLink(item.path) : '#'"
                      class="flex items-center cursor-pointer gap-3"
                      @click.capture.prevent.stop="itemClick(item, 2)"
                    >
                      <div
                        class="max-xl:flex-grow text-5 xl:text-4 font-700 xl:font-600 text-left truncate select-none"
                      >
                        <span :class="{ 'custom-underline': active(item, 3) }">
                          {{ item.name }}
                        </span>
                      </div>

                      <div
                        v-if="item.type == 'node' && level >= 2"
                        class="i-sl-arrow-right-1 size-4 xl:size-3"
                      ></div>
                    </NuxtLink>
                  </div>
                  <!-- level 3 -->
                  <div
                    v-if="level >= 3"
                    id="nav-level-1"
                    class="p-5 pt-22 max-xl:flex-grow max-xl:flex-basis-none xl:p-0 xl:ml--7 xl:pl-7 xl:border-l xl:border-scheppach-primary-300 flex flex-col max-xl:gap-6 xl:grid xl:grid-cols-subgrid xl:gap-y-3.5 xl:grid-auto-flow-col xl:grid-rows-10 max-xl:max-w-50% bg-scheppach-primary-50"
                    :class="{ 'xl:col-span-2': pages[3].items.length > 10 }"
                  >
                    <div class="relative">
                      <div
                        class="flex items-center cursor-pointer gap-3 xl:absolute xl:bottom-0"
                        @click="back"
                      >
                        <div class="size-4 i-sl-arrow-left-1 xl:hidden"></div>
                        <span
                          class="flex-grow text-left font-800 xl:font-700 truncate text-5 select-none"
                        >
                          <span
                            class="max-xl:hidden custom-underline"
                            @click="navigate(pages[3].header?.path ?? '#')"
                          >
                            {{ $t("header.all") }}
                            {{ pages[3].header?.name ?? "" }}
                          </span>
                          <span class="xl-hidden">
                            {{ pages[3].header?.name ?? "" }}
                          </span>
                        </span>
                      </div>
                    </div>

                    <NuxtLink
                      v-for="item of pages[3].items"
                      :key="item.id"
                      :to="item.path ? formatLink(item.path) : '#'"
                      class="flex items-center cursor-pointer gap-3"
                      @click="itemClick(item, 3)"
                    >
                      <div
                        class="max-xl:flex-grow text-5 xl:text-4 font-700 xl:font-600 text-left truncate select-none"
                      >
                        <span :class="{ 'custom-underline': item.active }">
                          {{ item.name }}
                        </span>
                      </div>

                      <div
                        v-if="item.type == 'node'"
                        class="i-sl-arrow-right-1 size-4 xl:size-3"
                      ></div>
                    </NuxtLink>
                  </div>
                  <button
                    type="button"
                    class="max-xl:hidden absolute right-0 i-sl-close size-5"
                    @click="close"
                  ></button>
                </div>
                <NuxtLink
                  v-if="banner"
                  class="block max-xl:hidden bg-white"
                  :href="banner.link"
                >
                  <SharedMediaImage
                    v-if="height >= 840"
                    :media="banner.media"
                    class="block h-63"
                    :extend="true"
                  />
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </ClientOnly>
</template>
